import { serverURL } from '../../config'
import { type BankData, type Plan, type IOrder, type PaymentMethod } from '../../model'
import { type AmwalOrderConfig } from '../../services/EventListeners'
import { type OrderAction, OrderActionType } from '../reducers/order'

type dispatchFunction = (a: OrderAction) => void

export function clearOrder () {
  return (dispatch: dispatchFunction) => {
    dispatch({ type: OrderActionType.CLEAR_DATA })
  }
}

export function setOrder (orderData: IOrder) {
  return (dispatch: dispatchFunction) => {
    dispatch({
      type: OrderActionType.SET_ORDER,
      order: orderData
    })
  }
}

export function fetchOrder (config: AmwalOrderConfig
) {
  return (dispatch: dispatchFunction) => {
    const fetchPromise = fetch(`${serverURL}/transactions/${config.id}?webauth=1`, {
      method: 'GET',
      credentials: 'include'
    })

    fetchPromise
      .then(async response => await response.json())
      .then(orderData => {
        dispatch({
          type: OrderActionType.SET_ORDER,
          order: orderData,
          session_id: orderData.session_id,
          config: {
            ...config,
            shippingMethods: config.shippingMethods ?? []
          }
        })
      }).catch(err => { console.log(err) })
  }
}

export function setConfig (
  config: AmwalOrderConfig) {
  return (dispatch: dispatchFunction) => {
    dispatch({
      type: OrderActionType.SET_ORDER,
      config: {
        ...config,
        shippingMethods: config.shippingMethods ?? []
      }
    })
  }
}

export function setPaymentMethod (
  paymentMethod: PaymentMethod) {
  return (dispatch: dispatchFunction) => {
    dispatch({
      type: OrderActionType.SET_PAYMENT_METHOD,
      paymentMethod
    })
  }
}

export function setInstallmentInfo (
  selectedInstallmentBank?: BankData, selectedInstallmentPlan?: Plan) {
  return (dispatch: dispatchFunction) => {
    dispatch({
      type: OrderActionType.SET_INSTALLMENT_INFO,
      selectedInstallmentBank,
      selectedInstallmentPlan
    })
  }
}
